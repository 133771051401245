import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class GlobalService {
    private loaderActivoFuente = new BehaviorSubject<boolean>(false);
    private loaderActivo = this.loaderActivoFuente.asObservable();

    constructor(
    ) {

    }
    cambiarEstatusLoader(valor: boolean): void {
        this.loaderActivoFuente.next(valor);
    }

    getValorEstatusLoader(): boolean {
        return this.loaderActivoFuente.getValue();
    }

}