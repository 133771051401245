import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from './modulos/core/core.module';

import { PrincipalComponent } from './modulos/core/principal/principal.component';

import { GlobalService } from './servicios/global.service';
import * as $ from 'jquery';

@NgModule({
  imports: [
    BrowserModule,
    CoreModule,
    BrowserAnimationsModule
  ],
  declarations: [
  ],
  providers: [GlobalService],
  exports: [
  ],
  bootstrap: [PrincipalComponent]
})
export class AppModule { }
