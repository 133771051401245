import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrincipalComponent } from './principal/principal.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';

import { Ng2PageScrollModule } from 'ng2-page-scroll';
import { HttpModule, Http, Headers } from '@angular/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MnFullpageModule } from 'ngx-fullpage';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MenuLenguajesComponent } from './menu-lenguajes/menu-lenguajes.component';

import { SlimScroll } from '../../directivas/slimscroll.directive';
//import { ImgMapComponent } from 'ng2-img-map';

import { ImgMapComponent  } from '../../../assets/lib/ng2-img-map';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  imports: [
    CommonModule,
    CarouselModule,
    Ng2PageScrollModule,
    MnFullpageModule.forRoot(),
    HttpClientModule,
    HttpModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    PrincipalComponent,
    SlimScroll,
    MenuLenguajesComponent,
    ImgMapComponent
  ],
  exports: [
    PrincipalComponent,
    ImgMapComponent
  ],
  providers: []
})
export class CoreModule { }
