import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-menu-lenguajes',
  templateUrl: './menu-lenguajes.component.html',
  styleUrls: ['./menu-lenguajes.component.css']
})
export class MenuLenguajesComponent implements OnInit {
  public supportedLanguages: any[] = [
    { displayActive: 'assets/img/inicio/espaniolActive.png', displayInactive: 'assets/img/inicio/espaniolInactive.png', value: 'es', active: true },
    { displayActive: 'assets/img/inicio/inglesActive.png', displayInactive: 'assets/img/inicio/inglesInactive.png', value: 'en', active: false },
/*     { displayActive: 'assets/img/inicio/alemanActive.png', displayInactive: 'assets/img/inicio/alemanInactive.png', value: 'al', active: false },
    { displayActive: 'assets/img/inicio/japonesActive.png', displayInactive: 'assets/img/inicio/japonesInactive.png', value: 'zh', active: false }, */
  ];
  constructor(private translate: TranslateService) {
    translate.setDefaultLang('es');
  }

  ngOnInit() {
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    this.supportedLanguages.forEach(element => {
      if (element.value == language) {
        element.active = true;
      } else {
        element.active = false;
      }
    });
  }


  isCurrentLang(lang: string) {
    return lang === this.translate.currentLang;
  }

}
