import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { ViewChild } from '@angular/core';
import { MnFullpageOptions } from 'ngx-fullpage';
import { ModalDirective } from 'ngx-bootstrap';
import { Configuracion } from '../../../entidades/Configuracion';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
declare let ga: Function;
import { ImgMapComponent } from '../../../../assets/lib/ng2-img-map';
import { Http, Response, Headers, RequestOptions } from '@angular/http';

@Component({
	selector: 'principal',
	templateUrl: './principal.component.html',
	styleUrls: ['./principal.component.css'],
	host: {
		'(window:resize)': 'onResize($event)',
	},
	providers: [
		{ provide: CarouselConfig, useValue: { interval: 0 } }
	]
})
export class PrincipalComponent implements OnInit, AfterViewInit {
	@ViewChild('modalNoticia') public modalNoticia: ModalDirective;
	@ViewChild('modalBolsaTrabajo') public modalBolsaTrabajo: ModalDirective;
	@ViewChild('modalPoliticas') public modalPoliticas: ModalDirective;
	@ViewChild('modalAvisoPrivacidad') public modalAvisoPrivacidad: ModalDirective;
	@ViewChild('modalCondiciones') public modalCondiciones: ModalDirective;
	@ViewChild('modalPreguntas') public modalPreguntas: ModalDirective;

	@ViewChild('modalPrecios') public modalPrecios: ModalDirective;
	@ViewChild('modalRecorridos') public modalRecorridos: ModalDirective;

	@ViewChild('modalMapa') public modalMapa: ModalDirective;

	@ViewChild('imgMap') public imgMap: ImgMapComponent;
	@ViewChild('imgMapMovil') public imgMapmovil: ImgMapComponent;
	markers: number[][] = [];
	markersMovil: number[][] = [];
	marker: string = '';
	noticia: Configuracion = new Configuracion();

	@Input() public options: MnFullpageOptions = new MnFullpageOptions({
		scrollingSpeed: 1500,
		menu: '.menu',
		css3: true,
		navigation: true,
		normalScrollElements: '#modal',
		anchors: [
			'inicio', 'rutasMapas', 'conocerPuntosRutaCultural', 'contactanos'
		],
		slidesNavigation: true,
		controlArrows: false,
		afterLoad: function (anchorLink, index) {
			var loadedSection = $(this);
			var titulo: string = '';
			//usando su link de anclaje
			if (anchorLink == 'inicio') {
				console.log('inicio');
				titulo = 'INICIO';
			}

			if (anchorLink == 'rutasMapas') {
				console.log('rutasMapas');
				titulo = 'RUTA Y MAPA';
			}

			if (anchorLink == 'conocerPuntosRutaCultural') {
				console.log('conocerPuntosRutaCultural');
				titulo = 'PUNTOS DE RUTA CULTURAL';
			}

			if (anchorLink == 'contactanos') {
				console.log('contactanos');
				titulo = 'CONTÁCTANOS';
			}
			ga('send', {
				hitType: 'pageview',
				screenName: titulo,
				title: titulo,
				page: titulo
			});
		}
	});
	public innerHeight: number;

	constructor(
		private http: Http
	) {
		this.innerHeight = (window.innerHeight);
	}

	ngOnInit() {
		//this.markers = [[22, 18], [12, 55], [28, 80], [34, 54], [44, 19], [47, 66], [58, 40], [71, 35], [72, 88], [84, 68], [87, 26], [97, 63]];
	//	this.markersMovil = [[51, 19], [76, 22]];
	}

	onChange(marker: number[]) {
		if (marker != null) {
		//	console.log('Marker', marker[0] + "-" + marker[1]);
			let paradero = marker[0] + "-" + marker[1];
			this.marker = paradero;
			this.abreModal("modalMapa");
		} else {
			//console.log(marker);
		}
	}

	onMark(marker: number[]) {
		//console.log('Marker', marker);
	}

	ngAfterViewInit() {
		this.http.get('../../assets/config/configuracion.json')
			.subscribe((value: any) => {
				this.noticia = JSON.parse(value._body)
				this.abreNoticia();
			});
			
		

	}

	abreNoticia(): void {
		if (this.noticia.mostrarNoticia) {
			this.modalNoticia.show();
		}
	}

	abreModal(tipo: string) {
		switch (tipo) {
			case 'modalPrecios': this.modalPrecios.show();
				break;
			case 'modalRecorridos': this.modalRecorridos.show();
				break;
			case 'modalPreguntas': this.modalPreguntas.show();
				break;
			case 'modalAvisoPrivacidad': this.modalAvisoPrivacidad.show();
				break;
			case 'modalCondiciones': this.modalCondiciones.show();
				break;
			case 'modalMapa': this.modalMapa.show();
				break;
			default: break;
		}
	}

	onResize(event) {
		this.innerHeight = (event.target.innerHeight);
	}

}


